import { merge } from 'lodash';

import { au, ca, nz, uk, us } from './countryPlan';
import { global, globalPro } from './globalPlan';

import type { LocalePlan, LocaleProPlan } from './types';
import type { LocaleId } from '@sharesight/gatsby-plugin-sharesight-localization';

/**
 * Full pricing plans for all locales.
 */
export const pricingPlans: { [key in LocaleId]: LocalePlan } = {
  global,
  au: merge({}, global, au),
  ca: merge({}, global, ca),
  nz: merge({}, global, nz),
  uk: merge({}, global, uk),
  us: merge({}, global, us),
};

/** TODO: can swap out with different localised pricing structures in the future */
export const proPricingPlans: { [key in LocaleId]: LocaleProPlan } = {
  global: globalPro,
  au: globalPro,
  ca: globalPro,
  nz: globalPro,
  uk: globalPro,
  us: globalPro,
};
