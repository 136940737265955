import type { LocalePlan, LocaleProPlan } from './types';

/**
 * The global pricing plan, which is actually the US pricing plan.
 * This is the base plan that all other pricing plans will 'extend'.
 */
export const global: LocalePlan = {
  Free: {
    buttonText: 'Get started',
    buttonAppearance: 'secondary',

    price: 0,
    annualPrice: 0,
    portfolios: 1,
    customGroups: 1,
    holdings: 10,
    reportsText: 'Limited',
    supportLevel: 'Basic',

    supportedMarkets: true,
    automaticAdjustments: true,
    priceAndPortfolioAlerts: true,
    mobileApp: true,
    tradeConfirmationEmails: true,
    recordUnlistedInvestments: true,
    portfolioSharing: true,
    cashAccounts: false,
    benchmarking: false,
    fileAttachments: false,
    labelsReportFiltering: false,
    xeroIntegration: false,

    performanceReport: true,
    taxableIncomeReport: true,
    historicalCostReport: true,
    soldSecuritiesReport: true,
    allTradesReport: true,
    capitalGainsTaxReport: false,
    unrealisedCapitalGainsReport: false,
    futureIncomeReport: false,
    diversityReport: false,
    contributionAnalysisReport: false,
    tradersTaxReport: false,
    foreignInvestmentFundReport: false,
    multiCurrencyValuationReport: false,
    multiPeriodReport: false,
  },

  Starter: {
    color: 'orangeHighlight',
    buttonText: 'Get started',
    buttonAppearance: 'secondary',
    price: 9.33,
    annualPrice: 84,
    portfolios: 1,
    customGroups: 3,
    holdings: 30,
    reportsText: 'Limited',
    supportLevel: 'Standard',

    supportedMarkets: true,
    automaticAdjustments: true,
    priceAndPortfolioAlerts: true,
    mobileApp: true,
    tradeConfirmationEmails: true,
    recordUnlistedInvestments: true,
    portfolioSharing: true,
    cashAccounts: true,
    benchmarking: true,
    fileAttachments: true,
    labelsReportFiltering: false,
    xeroIntegration: false,

    performanceReport: true,
    taxableIncomeReport: true,
    historicalCostReport: true,
    soldSecuritiesReport: true,
    allTradesReport: true,
    capitalGainsTaxReport: false,
    unrealisedCapitalGainsReport: false,
    futureIncomeReport: false,
    diversityReport: false,
    contributionAnalysisReport: false,
    tradersTaxReport: false,
    foreignInvestmentFundReport: false,
    multiCurrencyValuationReport: false,
    multiPeriodReport: false,
  },

  Investor: {
    color: 'primaryHighlight',
    buttonText: 'Get started',
    buttonAppearance: 'primary',
    price: 24,
    annualPrice: 216,
    portfolios: 4,
    customGroups: 5,
    holdings: 'Unlimited',
    reportsText: 'Advanced',
    supportLevel: 'Standard',

    supportedMarkets: true,
    automaticAdjustments: true,
    priceAndPortfolioAlerts: true,
    mobileApp: true,
    tradeConfirmationEmails: true,
    recordUnlistedInvestments: true,
    portfolioSharing: true,
    cashAccounts: true,
    benchmarking: true,
    fileAttachments: true,
    labelsReportFiltering: true,
    xeroIntegration: true,

    performanceReport: true,
    taxableIncomeReport: true,
    historicalCostReport: true,
    soldSecuritiesReport: true,
    allTradesReport: true,
    capitalGainsTaxReport: false,
    unrealisedCapitalGainsReport: false,
    futureIncomeReport: true,
    diversityReport: true,
    contributionAnalysisReport: true,
    tradersTaxReport: false,
    foreignInvestmentFundReport: false,
    multiCurrencyValuationReport: false,
    multiPeriodReport: false,
  },

  Expert: {
    color: 'navyBlueHighlight',
    buttonText: 'Get started',
    buttonAppearance: 'secondary',
    price: 31,
    annualPrice: 279,
    portfolios: 10,
    customGroups: 10,
    holdings: 'Unlimited',
    reportsText: 'Full',
    supportLevel: 'Priority',

    supportedMarkets: true,
    automaticAdjustments: true,
    priceAndPortfolioAlerts: true,
    mobileApp: true,
    tradeConfirmationEmails: true,
    recordUnlistedInvestments: true,
    portfolioSharing: true,
    cashAccounts: true,
    benchmarking: true,
    fileAttachments: true,
    labelsReportFiltering: true,
    xeroIntegration: true,

    performanceReport: true,
    taxableIncomeReport: true,
    historicalCostReport: true,
    soldSecuritiesReport: true,
    allTradesReport: true,
    capitalGainsTaxReport: false,
    unrealisedCapitalGainsReport: false,
    futureIncomeReport: true,
    diversityReport: true,
    contributionAnalysisReport: true,
    tradersTaxReport: false,
    foreignInvestmentFundReport: false,
    multiCurrencyValuationReport: true,
    multiPeriodReport: true,
  },
};

export const globalPro: LocaleProPlan = {
  '5-19': {
    price: 13.5,
    monthlyPrice: 67.5,
  },
  '20-49': {
    price: 12.0,
    monthlyPrice: 240,
  },
  '50-99': {
    price: 10.5,
    monthlyPrice: 525,
  },
  '100+': {
    price: 9.2,
    monthlyPrice: 920,
  },
};
