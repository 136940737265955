import type { PartialDeep } from 'type-fest';
import type { LocalePlan } from './types';

/**
 * Each of the properties defined in the following pricing plans override
 * the corresponding properties in the global pricing plan.
 *
 * These should not be used directly as they are not full plans
 * - instead use `pricingPlans` in pricingPlans.ts
 */
export const au: PartialDeep<LocalePlan> = {
  Free: {
    capitalGainsTaxReport: false,
  },

  Starter: {
    price: 25.33,
    annualPrice: 228,
    capitalGainsTaxReport: true,
  },

  Investor: {
    price: 38.67,
    annualPrice: 348,
    capitalGainsTaxReport: true,
    unrealisedCapitalGainsReport: true,
  },

  Expert: {
    price: 65.33,
    annualPrice: 588,
    capitalGainsTaxReport: true,
    unrealisedCapitalGainsReport: true,
  },
};

export const ca: PartialDeep<LocalePlan> = {
  Starter: {
    price: 12,
    annualPrice: 108,
  },

  Investor: {
    price: 31,
    annualPrice: 279,
    portfolios: 4,
    capitalGainsTaxReport: true,
  },

  Expert: {
    price: 39,
    annualPrice: 351,
    capitalGainsTaxReport: true,
  },
};

export const nz: PartialDeep<LocalePlan> = {
  Starter: {
    price: 25.33,
    annualPrice: 228,
  },

  Investor: {
    price: 38.67,
    annualPrice: 348,
  },

  Expert: {
    price: 65.33,
    annualPrice: 588,
    tradersTaxReport: true,
    foreignInvestmentFundReport: true,
  },
};

export const uk: PartialDeep<LocalePlan> = {
  Starter: {
    price: 8,
    annualPrice: 72,
  },

  Investor: {
    price: 18.67,
    annualPrice: 168,
  },

  Expert: {
    price: 28,
    annualPrice: 252,
  },
};

// Global pricing plan is the US plan.
export const us: PartialDeep<LocalePlan> = {};
